import React from "react";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Hero from "common/hero";
import Seo from "common/seo";
import CategoryDetails from "common/category-details";
import { TypeA, TypeC } from "common/callouts";

import heroImageRef from "images/body/eczema/body-eczema-hero.webp";
import productInfoIcon from "images/icons/icon-eczema-condition.png";
import productInfoImg from "images/body/eczema/eczema-ph-scale.webp";
import img1 from "images/callouts/cout-advanced-repair-lotion-eczema.webp";
import img2 from "images/callouts/cout-relief-cream-eczema.webp";
import img4 from "images/callouts/cout-footer-eczema-itch-relief.webp";
import img5 from "images/callouts/cout-footer-aquaphor.webp";
import img6 from "images/callouts/cout-footer-dry-skin-therapy.webp";

import "./eczema.scss";

const MyHero = () => <Hero className="inner-hero" bgImagePath={heroImageRef} />;

function IndexPage({ pageContext }) {
  return (
    <Layout
      pageClass="page-body-eczema"
      breadcrumbData={pageContext.breadcrumb}
      hero={<MyHero />}
    >
      <Seo
        pageTitle="Eczema"
        pageDescription="Learn about Eucerin and Aquaphor therapeutic solutions for eczema"
      />
      <div className="inner-body-content">
        <section className="inner-centered-container">
          <CategoryDetails
            headBgColor="#A70531"
            headText="ECZEMA"
            infoText="Eczema-prone skin needs pH-optimized protection"
            bodySpanText="The therapeutic goal of eczema treatment&nbsp;"
            bodyText="is to help reduce the incidence of flares."
            productInfoIcon={productInfoIcon}
            productInfoIconAlt="Icon for therapeutic goal of eczema treatment"
            productInfoImg={productInfoImg}
            productInfoImgAlt="pH Scale for Skin Health"
            imgHeader="Acidic skin pH is essential for skin health."
            imgCaption="<p class='font-bold'>For patients with atopic dermatitis,</p><p class='font-bold'> a lower pH of the skin is a therapeutic goal.<sup>1</sup></p><p>Skin with pH <5.0 is associated with increased</p><p>mositure and reduced scaling. <span class='font-bold display-spacer'>4.5-5.5 pH is optimal for skin health.</span></p>"
          >
            <h5 className="shiraz-red">
              The Eucerin<sup>&reg;</sup> proactive therapeutic regimen for
              eczema
            </h5>
            <p>
              It's designed for prevention through daily maintenance. Products
              are skin pH-optimized and formulated with purposeful, clinically
              proven ingredients to soothe and relieve eczema-prone skin, as
              well as help prevent flares.
            </p>
          </CategoryDetails>
        </section>
        <section className="callouts-container">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={8} lg={4} order={{ xs: 2, lg: 1 }}>
                <TypeC
                  head1={`Eucerin<sup>&reg;</sup> Eczema Relief Cream & Body Wash`}
                  head1Css="shiraz-red"
                  buttonText="See product information"
                  buttonUrl="/body/eczema/eucerineczemabodywash"
                  className="center-v"
                >
                  Helps protect eczema-prone skin with a calming wash
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 1, lg: 2 }}>
                <img
                  src={img1}
                  alt="Eucerin Eczema Relief Cream & Body Wash"
                  className="cout-product-img"
                />
              </Col>
              <Col sm={12} md={8} lg={4} order={{ xs: 4, lg: 3 }}>
                <TypeC
                  head1={`Eucerin<sup>&reg;</sup> Eczema Relief Cream`}
                  head1Css="shiraz-red"
                  buttonText="See product information"
                  buttonUrl="/body/eczema/eucerineczemacream"
                  className="center-v"
                >
                  Helps prevent and soothe eczema flares with purposeful
                  ingredients
                </TypeC>
              </Col>
              <Col sm={12} md={4} lg={2} order={{ xs: 3, lg: 4 }}>
                <img
                  src={img2}
                  alt="Eucerin Eczema Relief Cream"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
        </section>

        <div className="inner-centered-container">
          <p className="references">
            <span className="font-bold">Reference: 1.</span> Panther DJ, Jacob
            SE. The importance of acidification in atopic eczema: an
            underexplored avenue for treatment.{" "}
            <span className="font-italic">J Clin Med</span>. 2015;4(5):970-978.
          </p>
        </div>

        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img5}
                  imgAlt="Dry, Damaged Skin"
                  caption="Take the Aquaphor<sup>®</sup> Approach to help heal dry, damaged skin"
                  buttonText="Dry, Damaged Skin"
                  buttonUrl="/body/damagedskin"
                  imgStyles={{ height: 165 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img4}
                  imgAlt="Itch Relief"
                  caption="Offer immediate and <nobr>long-lasting</nobr> itch relief"
                  buttonText="Itch Relief"
                  buttonUrl="/body/itch"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={4}>
                <TypeA
                  imgRef={img6}
                  imgAlt="Dry Skin Therapy"
                  caption="Replenish what nature provided for dry skin"
                  buttonText="Dry Skin Therapy"
                  buttonUrl="/body/dryskin"
                  imgStyles={{ height: 125 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default IndexPage;
